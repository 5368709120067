<template>
  <div>
    <div
      :class="{
        topNavBox: true
      }"
    >
      <div class="topbox clearfix allN">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="searchbox fl clearfix">
          <input
            v-model="inputValue"
            class="fl"
            ref="input"
            placeholder="書名/作家名"
            @keyup.enter="searchquery"
            type="text"
          />
          <img
            @click="searchquery"
            v-show="!isShowlist"
            class="fl"
            src="~img/home/icon_search@2x.png"
            alt=""
          />
          <img
            v-show="isShowlist"
            class="fl"
            @click="cancleSearch"
            src="~img/account/common_cancel@2x.png"
            alt=""
          />
        </p>
      </div>
    </div>
    <!-- 歷史搜索 -->
    <div v-if="!isShowlist" class="history">
      <h4>
        歷史搜索
      </h4>
      <ul class="clearfix">
        <li
          class="fl"
          v-for="item in historylist"
          :key="item"
          @click="chooseone(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="ShelfRecommend normalBox threeShelf" v-if="isShowlist">
      <ul
        class="clearfix shelf1"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="240"
      >
        <li v-for="book in shelf" class="threeItem clearfix" :key="book.id">
          <router-link :to="'/book/' + book.id" class="fl">
            <img v-lazy="book.iconUrlSmall" alt="" />
          </router-link>
          <div class="fl rbox">
            <router-link
              :to="'/book/' + book.id"
              v-html="book.name"
              class="bookname"
            >
            </router-link>
            <div class="bookintro">
              <p class="introCon">{{ book.introduce }}</p>
              <div class="clearfix">
                <router-link
                  v-html="book.authorname"
                  :to="'/book/' + book.id"
                  class="fl authorName"
                ></router-link>
                <div class="fr clearfix">
                  <router-link :to="'/book/' + book.id" class="tag fl">{{
                    book.sortName
                  }}</router-link>
                  <router-link :to="'/book/' + book.id" class="fl tag finish">{{
                    book.finished ? "完結" : "連載中"
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-show="showMore & isShowlist" class="nonehint">
      <img src="~img/account/nonehint.png" alt="" />
    </div>
  </div>
</template>
<script>
import { searchAll } from "apiurl/search";
import $ from "jquery";
export default {
  data() {
    return {
      shelf: [],
      showMore: false,
      loading: false,
      hasNextPage: true,
      inputValue: null,
      isShowlist: false,
      pageNum: 1,
      historylist: []
    };
  },
  created() {
      this.$nextTick(()=>{
          this.$refs.input.focus();
      })
    if (localStorage.getItem("history")) {
      this.historylist = localStorage.getItem("history").split(",");
    }
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    async loadMore() {
      if (!this.hasNextPage && this.isShowlist) {
        this.loading = false;
        this.pageNum++;
        let res = await searchAll({
          page: this.pageNum,
          size: 10,
          queryString: this.inputValue
        });
        if (res.success) {
          res.data.content.forEach(item => {
            item.introduce = item.introduce.substring(0, 30) + "...";
          });
          this.hasNextPage = res.data.last;
          this.shelf = [...this.shelf, ...res.data.content];
          this.$nextTick(() => {
            $(".bookname span").css({ "font-size": "inherit" });
            $(".authorName span").css({ "font-size": "inherit" });
          });
          if (this.hasNextPage) {
            this.showMore = true;
            this.loading = true;
          } else {
            this.showMore = false;
            this.loading = false;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    },
    chooseone(item) {
      this.inputValue = item;
      this.searchquery();
    },
    searchquery() {
      this.hasNextPage = true;
      document.documentElement.scrollTop = "0px";
      if (this.historylist.length < 10) {
        if (this.historylist.length > 0) {
          this.optionA();
          localStorage.setItem("history", this.historylist.toString());
        } else {
          this.optionA();
          localStorage.setItem("history", this.historylist.toString());
        }
      } else {
        this.optionA();
        this.historylist = this.historylist(0, 10);
        localStorage.setItem("history", this.historylist.toString());
      }
      searchAll({
        page: 1,
        size: 10,
        queryString: this.inputValue
      }).then(res => {
        res.data.content.forEach(item => {
          item.introduce = item.introduce.substring(0, 30) + "...";
        });
        this.isShowlist = true;
        this.hasNextPage = res.data.last;
        this.shelf = res.data.content;
        this.$nextTick(() => {
          $(".bookname span").css({ "font-size": "inherit" });
          $(".authorName span").css({ "font-size": "inherit" });
          if (this.hasNextPage) {
            this.showMore = true;
            this.loading = true;
          } else {
            this.showMore = false;
            this.loading = false;
          }
        });
      });
    },
    optionA() {
      this.historylist.unshift(this.inputValue);
      this.historylist = new Set(this.historylist);
      this.historylist = [...this.historylist];
    },
    cancleSearch() {
      this.isShowlist = false;
      this.shelf = [];
      this.inputValue = null;
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.topNavBox
    background rgba(255, 255, 255, 1)
    box-shadow 0px 4px 6px 0px rgba(0, 0, 0, 0.05)
    position fixed
    top 0
    left 0
    width 100%
    z-index 10
    border-bottom 1px solid #fff
    box-shadow 0 0 0 0
.threeShelf
    margin-top 130px
.introCon
    padding-bottom 16px
    border-bottom 2px solid #E9ECF0
    line-height 33px
    margin-bottom 15px
.authorName
    font-size 22px
    color rgba(172, 175, 194, 1)
    line-height 30px
.topbox
    height 100px
    line-height 100px
    padding 0 27px
    position relative
    .back
        width 50px
        height 37px
        line-height 100px
        margin-top 13.5px
        img
            width 18px
            height 37px
    .searchbox
        width 653px
        height 72px
        background rgba(249, 249, 249, 1)
        border-radius 36px
        margin-left 72px
        input
            width 570px
            height 100%
            margin-top 0
            background rgba(249, 249, 249, 1)
            border-radius 36px
            font-size 28px
            padding-left 29px
            box-sizing border-box
            color #333333
            placeMain(#999, 28px, 0px)
        img
            width 36px
            height 36px
            margin-top 18px
.nonehint
    margin 65px auto 30px
    width 209px
    img
        width 209px
.history
    margin-top 130px
    margin-left 26px
    font-size 30px
    color #333
    ul
        margin-top 28px
        li
            padding 7px 42px
            max-width 80%
            height 42px
            color #999
            background:rgba(243,248,253,1);
            border-radius:29px;
            margin-right 26px
            margin-bottom 28px
            overflow: hidden;
            text-overflow:ellipsis;//文本溢出顯示省略號
            white-space:nowrap;//文本不會換行（單行文本溢出）
</style>
